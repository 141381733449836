import { DataGrid } from "@mui/x-data-grid";
import {
  Card,
  CardHeader,
  CardContent,
  useMediaQuery,
  Typography,
  Box,
  Grid,
  Button,
  Tabs,
  Tab,
  FormHelperText,
  Fade,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { green } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useCallback, useEffect, useState } from "react";
import ContractorTab from "./permitPackageTabs/ContractorTab";
import OwnerTab from "./permitPackageTabs/OwnerTab";
import NotarizationTab from "./permitPackageTabs/NotarizationTab";
import RecordingTab from "./permitPackageTabs/RecordingTab";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import PermitSettings from "./permitPackageTabs/PermitSettings";
import {
  updatePermitPackage,
  updatePermitPackageName,
} from "../../store/actions/dataActions";
import { cityScraperList } from "../../data/cityScraperList";
import CitySiteTab from "./permitPackageTabs/CitySiteTab";

const MyPermitGrid = (props) => {
  console.log(props?.selectedRow);
  const dispatch = useDispatch();
  const [isFading, setIsFading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [cancelPermitComment, setCancelPermitComment] = useState("");
  const noc_data = useSelector((state) => state.nocData.nocData);

  const permitPackages = useSelector((state) => state.nocData?.permitPackages);
  const [editObj, setEditObj] = useState({
    nameError: "",
    nameRespText: "",
    packageName: "",
  });
  const [editPNameOpen, setEditPNameOpen] = useState(false);
  const [recordLoading, setRecordLoading] = useState(false);

  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handleRightChange = (event, newValue) => {
    setIsFading(true);
    setTimeout(() => {
      if (props?.rightValue !== newValue) {
        props?.setRightValue(newValue);
        setIsFading(false);
      }
    }, 300); // Duration should match the CSS transition duration
  };

  const handleLeftChange = (event, newValue) => {
    if (props?.leftValue !== newValue) {
      props?.setLeftValue(newValue);
    }
  };

  const handleCancelPermit = async () => {
    try {
      props?.setCancelLoading(true);
      const voidPermit = props?.selectedRow?.city_scraper?.permit_id;

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/cancel-permit-package`,
        {
          task_id: props?.selectedRow?.task_id,
          currentSession: props?.currentSession,
          voidPermit: voidPermit,
          cancelPermitComment: cancelPermitComment,
        },
        {
          withCredentials: true,
        }
      );
      if (resp?.data?.success) {
        props?.setSelectedRow(null);
        handlePaginationModelChange(
          { page: props?.page, pageSize: props?.pageSize },
          "detail"
        );
        // Update row count
        props?.setRowCount((prevRowCount) => prevRowCount - 1);
      }
    } catch (error) {
    } finally {
      props?.setCancelLoading(false);
    }
  };

  const handleEditPackageName = async () => {
    try {
      props?.setCancelLoading(true);

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/edit-package-name`,
        {
          task_id: props?.selectedRow?.task_id,
          packageName: editObj?.packageName,
          currentSession: props?.currentSession,
        },
        {
          withCredentials: true,
        }
      );
      if (resp?.data?.success) {
        setEditObj((prev) => ({
          ...prev,
          nameRespText: resp?.data?.message,
        }));
        dispatch(
          updatePermitPackageName({
            task_id: props?.selectedRow?.task_id,
            package_name: editObj?.packageName,
          })
        );
        setEditPNameOpen(false);
        setSnackOpen(true);
      }
    } catch (error) {
    } finally {
      props?.setCancelLoading(false);
    }
  };

  const handlePaginationModelChange = (model, details) => {
    const newPage = model.page;
    const newRowsPerPage = model.pageSize;
    props?.setPage(newPage);
    props?.setPageSize(newRowsPerPage);
    fetchData(newPage, newRowsPerPage);
  };

  const fetchData = async (newPage, newRowsPerPage) => {
    props?.setDataLoading(true);
    await props?.dispatch(props?.getPermitPackages(newPage, newRowsPerPage));
    props?.setDataLoading(false);
  };

  const handleStartRecord = async () => {
    try {
      setRecordLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/start-manual-recording`,
        {
          task_id: props?.selectedRow?.task_id,
          currentSession: props?.currentSession,
        },
        {
          withCredentials: true,
        }
      );
      if (resp?.data?.success) {
        await dispatch(updatePermitPackage(resp?.data?.data));
      }
    } catch (error) {
    } finally {
      setRecordLoading(false);
    }
  };

  const handleRowClick = useCallback(
    (params, event, details) => {
      if (props?.cancelLoading) {
        return;
      }

      const row = params.row;

      // Only update selectedRow if it's different from the current one
      if (props?.selectedRow?.id !== row?.id) {
        props?.setSelectedRow(row);
      }

      props?.setTempLoading(false);

      // Update leftValue only if necessary
      const newLeftValue = row?.selected_documents?.length - 1;
      if (
        row?.selected_documents?.length < props?.leftValue + 1 &&
        newLeftValue !== props?.leftValue
      ) {
        props?.setLeftValue(newLeftValue);
      }

      const newRightValue =
        row?.permit_package_created && !row?.notarized
          ? 2
          : row?.permit_package_created && row?.notarized && !row?.recorded
          ? 3
          : 0;
      console.log(newRightValue);
      console.log(props?.rightValue);
      if (newRightValue !== props?.rightValue) {
        props?.setRightValue(newRightValue);
      }
    },
    [props?.cancelLoading, props?.selectedRow]
  );

  useEffect(() => {
    if (props?.selectedRow) {
      // Set tempLoading to false if necessary
      if (props?.tempLoading !== false) {
        props?.setTempLoading(false);
      }

      // Update selectedRow only if necessary
      const updatedRow = permitPackages.find(
        (item) => item.task_id === props?.selectedRow.task_id
      );
      if (updatedRow && updatedRow !== props?.selectedRow) {
        props?.setSelectedRow(updatedRow);
      }
    }
  }, [permitPackages, props?.selectedRow, props?.tempLoading]);

  useEffect(() => {
    if (props?.selectedRow && !props?.permitDrawerOpen) {
      // If permit_package_created is true, ensure leftValue is 0
      if (props?.selectedRow?.permit_package_created) {
        if (props?.selectedValue !== 0) {
          if (props?.leftValue !== 0) {
            props?.setLeftValue(0);
          }
        }
      } else {
        const selectedDocs = props?.selectedRow.selected_documents;
        let lastCreatedIndex = 0;

        for (let i = 0; i < selectedDocs.length; i++) {
          const doc = selectedDocs[i];
          if (doc?.created) {
            lastCreatedIndex = i + 1;
          } else {
            break;
          }
        }

        if (props?.leftValue !== lastCreatedIndex) {
          props?.setLeftValue(lastCreatedIndex);
        }
      }
    }
  }, [props.selectedRow]);

  // useEffect(() => {
  //   if (props.selectedRow) {
  //      const documents = props?.selectedRow.documents;
  //     let selectedDocObj =  props?.selectedRow?.selected_documents[leftValue]

  //   }
  // }, [leftValue, props.selectedRow]);

  // Function to determine which component to render
  const getRightTabComponent = () => {
    switch (props?.rightValue) {
      case 0:
        return <OwnerTab {...props} />;
      case 1:
        return <ContractorTab {...props} />;
      case 2:
        return <NotarizationTab {...props} />;
      case 3:
        return (
          <RecordingTab
            {...props}
            recordLoading={recordLoading}
            handleStartRecord={handleStartRecord}
          />
        );
      case 4:
        return <CitySiteTab {...props} />;
      case 5:
        return (
          <PermitSettings
            {...props}
            handleCancelPermit={handleCancelPermit}
            handleEditPackageName={handleEditPackageName}
            editObj={editObj}
            setEditObj={setEditObj}
            editPNameOpen={editPNameOpen}
            setEditPNameOpen={setEditPNameOpen}
            cancelPermitComment={cancelPermitComment}
            setCancelPermitComment={setCancelPermitComment}
          />
        );
      default:
        return <OwnerTab {...props} />; // Default to Contractor if something goes wrong
    }
  };
  return (
    <>
      <Card sx={{ position: "relative", mb: 0, pb: 0 }}>
        <CardHeader
          title={`${" \u00A0\u00A0"}P E R M I T${" \u00A0\u00A0"}P A C K A G E S`}
        />

        <CardContent className="bottomCard-6" sx={{ p: 0, height: "72vh" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                flex: 0.8,
                minHeight: 0,
                overflow: "auto",
                borderBottom: "5px solid #d3d3d3",
              }}
            >
              {permitPackages?.length === 0 ? (
                <Typography mt={2} align="center" color="primary" variant="h6">
                  No Permit Package has been created yet.
                </Typography>
              ) : (
                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeader:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      "&:hover": {
                        ".MuiDataGrid-menuIcon, .MuiDataGrid-sortIcon, .MuiDataGrid-columnHeader--sorted":
                          {
                            visibility: "hidden",
                            pointerEvents: "none",
                          },
                        cursor: "default",
                      },
                    },
                    // Additionally target specific sort button
                    "& .MuiDataGrid-columnHeader--sortable": {
                      pointerEvents: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      padding: 0, // Adjust as needed
                      margin: 0, // Adjust as needed
                    },
                    "& .MuiDataGrid-columnHeadersInner": {
                      width: "100%", // Adjust as needed
                      padding: 0,
                    },
                    border: "1px solid #FCF2F0", // Change the border color of the entire DataGrid
                    "& .MuiDataGrid-columnHeader": {
                      backgroundColor: "#333333", // Change column header background color
                      color: "white", // Optional: Change text color for contrast
                    },
                    "& .MuiDataGrid-cell": {
                      borderColor: "#333333", // Change cell border color
                    },
                    "& .MuiDataGrid-columnSeparator": {
                      display: "none", // Optional: Hide the column separators
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeader:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-row": {
                      cursor: "pointer", // Change cursor to pointer for rows
                    },
                  }}
                  rows={permitPackages || []}
                  columns={props?.permitPackageColumn}
                  getRowId={(row) => row?.id}
                  onPaginationModelChange={(model, details) => {
                    handlePaginationModelChange(model, details);
                  }}
                  loading={props?.dataLoading}
                  paginationMode="server"
                  paginationModel={{
                    page: props?.page, // Set the current page value here
                    pageSize: props?.pageSize, // Set the page size value here
                  }}
                  rowCount={props?.rowCount}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  onRowClick={(params, event, details) => {
                    handleRowClick(params, event, details);
                  }}
                  pageSizeOptions={[5]}
                  rowHeight={34}
                />
              )}
            </Box>
            <Box
              sx={{
                flex: 1.05,
                minHeight: 0,
                overflow: "auto",
              }}
            >
              {!props?.selectedRow ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Typography variant="h5" color="primary">
                    {permitPackages?.length > 0
                      ? "You can get more information about a Project and manage it by selecting it"
                      : ""}
                  </Typography>
                </Box>
              ) : (
                <Grid container sx={{ width: "100%", height: "100%" }}>
                  <Grid item sm={8} sx={{ width: "100%" }}>
                    <Typography
                      variant="h5"
                      // color="primary"
                      sx={{
                        fontStyle: "italic",
                      }}
                      align="center"
                      mt={1}
                    >
                      Permit Package Documents
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          marginLeft: "6px",
                        }}
                      >
                        Created {props?.numCreated}/
                        {props?.selectedRow?.selected_documents.length}
                      </span>
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        minHeight: "86%",
                        maxHeight: "20rem",
                        mt: 1,
                        borderTop: "4px solid #d3d3d3",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Tabs
                          orientation="vertical"
                          value={props?.leftValue}
                          onChange={handleLeftChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          allowScrollButtonsMobile
                          aria-label="scrollable auto tabs example"
                          TabIndicatorProps={{
                            sx: {
                              left: 0, // Position the indicator on the left side
                              width: "3px", // Adjust the width of the indicator
                            },
                          }}
                          sx={{
                            alignItems: "flex-start", // Align the tabs to the left
                          }}
                        >
                          {props?.selectedRow?.selected_documents?.map(
                            (document, index) => {
                              const isEnabled =
                                props?.selectedRow?.permit_package_created ||
                                document?.created ||
                                (props?.selectedRow?.selected_documents[
                                  index - 1
                                ]?.created &&
                                  !document?.created);

                              return (
                                <Tab
                                  disabled={!isEnabled}
                                  key={index}
                                  label={
                                    <span
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {document.label}
                                      {props?.selectedRow?.selected_documents?.[
                                        index
                                      ]?.created ? (
                                        <CheckCircleIcon
                                          style={{
                                            color: green[500],
                                            marginLeft: 8,
                                            fontSize: "20px",
                                          }}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                  }
                                  disableRipple
                                  sx={{
                                    py: 0,
                                    alignItems: "flex-start", // Align the tabs to the left
                                    marginLeft: "24px",
                                    ".MuiTab-root": {
                                      justifyContent: "flex-start", // Align the tab content to the left
                                      textAlign: "left", // Align the text to the left
                                      // Set a consistent left margin for all tabs
                                    },
                                  }}
                                />
                              );
                            }
                          )}
                        </Tabs>
                      </Box>
                      {/* MIDDLE CONTENT */}
                      <Box
                        sx={{
                          borderLeft: "4px solid #d3d3d3",
                          ml: 1,
                          flex: 1,
                        }}
                      >
                        {props?.tempLoading ||
                        props?.selectedRow?.documents?.[props?.creatingDoc]
                          ?.status === "In Progress" ? (
                          <Box
                            sx={{
                              height: "100%",
                              mx: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h6"
                              align="center"
                              color="primary"
                            >
                              Please wait while the document is being created
                            </Typography>
                            <Box sx={{ textAlign: "center", mt: 2 }}>
                              <img
                                src="/img/gif/HI9M.gif"
                                alt="loading"
                                width={100}
                              />
                            </Box>
                          </Box>
                        ) : noc_data?.failed_counties.includes(
                            props?.selectedRow?.county
                          ) &&
                          props?.selectedRow?.documents?.noc?.is_created ===
                            false &&
                          props?.selectedRow?.documents?.noc?.status ===
                            "failed" ? (
                          <Box
                            sx={{
                              height: "100%",
                              mx: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h6"
                              align="center"
                              color="error"
                              px={2}
                            >
                              {props?.selectedRow?.county} Appraiser's website
                              is temporarily unavailable. Once the website can
                              be accessed, the NOC will be automatically
                              generated.
                            </Typography>
                            <Box sx={{ textAlign: "center", mt: 2 }}>
                              <img
                                src="/img/gif/HI9M.gif"
                                alt="loading"
                                width={100}
                              />
                            </Box>
                          </Box>
                        ) : props?.selectedRow?.documents?.[props?.creatingDoc]
                            ?.status === "failed" ? (
                          //   &&
                          // !props?.selectedRow?.documents?.[props?.creatingDoc]
                          <Box
                            sx={{
                              height: "100%",
                              mx: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h6"
                              align="center"
                              px={1}
                              color="error"
                            >
                              Based on the address you provided, we were unable
                              to locate the property
                            </Typography>
                            <Button
                              onClick={props?.handleCreate}
                              sx={{ mt: 1, minWidth: "10rem" }}
                              variant="contained"
                              color="warning"
                              endIcon={<EditIcon />}
                            >
                              Update Details
                            </Button>
                          </Box>
                        ) : props?.selectedRow?.documents?.[props?.creatingDoc]
                            ?.status === "more" ? (
                          <Box
                            sx={{
                              height: "100%",
                              mx: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h6"
                              align="center"
                              px={1}
                              color="error"
                            >
                              There are more than one property addresses found
                              for the given address
                            </Typography>
                            <Button
                              onClick={props?.handleCreate}
                              sx={{ mt: 1, minWidth: "10rem" }}
                              variant="contained"
                              color="warning"
                              endIcon={<EditIcon />}
                            >
                              Update Details
                            </Button>
                          </Box>
                        ) : !props?.selectedRow?.selected_documents[
                            props?.leftValue
                          ]?.created ? (
                          <Box
                            sx={{
                              height: "100%",
                              mx: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h6"
                              align="center"
                              color="primary"
                            >
                              The document has not yet been created
                            </Typography>
                            <Button
                              disabled={props?.cancelLoading}
                              onClick={props?.handleCreate}
                              sx={{ mt: 1, minWidth: "10rem" }}
                              variant="contained"
                              color="success"
                            >
                              Create
                            </Button>
                          </Box>
                        ) : (
                          props?.selectedRow?.selected_documents[
                            props?.leftValue
                          ]?.created && (
                            <>
                              {props?.selectedRow?.notarized && (
                                <Box
                                  sx={{
                                    mt: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <LoadingButton
                                    endIcon={<DownloadIcon />}
                                    variant="outlined"
                                    size="small"
                                    loading={props?.ppDownloading}
                                    onClick={() =>
                                      props?.handleDownloadNoc(
                                        props?.selectedRow,
                                        "all"
                                      )
                                    }
                                  >
                                    Download Notarized Package
                                  </LoadingButton>
                                </Box>
                              )}

                              <Box
                                sx={{
                                  height: "100%",
                                  mx: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  align="center"
                                  color="primary"
                                  px={1}
                                >
                                  {
                                    props?.selectedRow?.selected_documents[
                                      props?.leftValue
                                    ]?.label
                                  }{" "}
                                  {props?.selectedRow?.notarized
                                    ? "has been notarized"
                                    : "has been created"}
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: 1,
                                  }}
                                >
                                  {!props?.selectedRow?.notarized && (
                                    <Button
                                      disabled={
                                        Boolean(
                                          props?.instructionDownloading
                                        ) ||
                                        Boolean(props?.downloading) ||
                                        props?.selectedRow?.processing_permit
                                          ?.notarization?.started
                                      }
                                      onClick={props?.handleCreate}
                                      sx={{ mt: 1, minWidth: "10rem" }}
                                      variant="contained"
                                      color="warning"
                                      endIcon={<EditIcon />}
                                    >
                                      Update Document
                                    </Button>
                                  )}

                                  <LoadingButton
                                    disabled={Boolean(
                                      props?.instructionDownloading
                                    )}
                                    loading={
                                      props?.downloading ===
                                      props?.selectedRow?.selected_documents?.[
                                        props?.leftValue
                                      ]?.name +
                                        props?.selectedRow?.task_id
                                    }
                                    onClick={() =>
                                      props?.handleDownloadNoc(
                                        props?.selectedRow,
                                        props?.selectedRow
                                          ?.selected_documents?.[
                                          props?.leftValue
                                        ]?.name
                                      )
                                    }
                                    sx={{ mt: 1, minWidth: "10rem" }}
                                    variant="contained"
                                    color={
                                      props?.selectedRow?.notarized
                                        ? "success"
                                        : "primary"
                                    }
                                    endIcon={<DownloadIcon />}
                                  >
                                    {props?.selectedRow?.notarized
                                      ? "Download Notarized Form"
                                      : "Download Form"}
                                  </LoadingButton>
                                </Box>
                                {props?.selectedRow?.selected_documents?.[
                                  props?.leftValue
                                ]?.name === "pais" &&
                                  props?.selectedRow?.selected_documents?.[
                                    props?.leftValue
                                  ]?.created && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <LoadingButton
                                        disabled={Boolean(props?.downloading)}
                                        loading={
                                          props?.instructionDownloading ===
                                          props?.selectedRow
                                            ?.selected_documents?.[
                                            props?.leftValue
                                          ]?.name +
                                            "inst" +
                                            props?.selectedRow?.task_id
                                        }
                                        onClick={
                                          props?.handleDownloadInstructions
                                        }
                                        sx={{ mt: 1, minWidth: "10rem" }}
                                        color="primary"
                                        endIcon={<UploadFileIcon />}
                                      >
                                        Download Instructions
                                      </LoadingButton>
                                    </Box>
                                  )}
                              </Box>
                            </>
                          )
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item sm={4} sx={{ borderLeft: "4px solid #d3d3d3" }}>
                    <Box
                      sx={{
                        maxWidth: "100%",
                        bgcolor: "background.paper",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Tabs
                        value={props?.rightValue}
                        onChange={handleRightChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        aria-label="scrollable auto tabs example"
                        TabIndicatorProps={{
                          style: {
                            height: "3px", // Customize the height of the indicator
                            bottom: "10px", // Adjust the position closer to the text
                          },
                        }}
                      >
                        <Tab label="Property Owner" disableRipple />
                        <Tab label="Contractor" disableRipple />
                        <Tab label="Notarization" disableRipple />
                        <Tab label="Recording" disableRipple />
                        <Tab label="City Permit" disableRipple />
                        <Tab label="Settings" disableRipple />
                      </Tabs>
                    </Box>

                    <Box
                      sx={{ width: "100%" }}
                      className={
                        isFading
                          ? "fade-exit fade-exit-active"
                          : "fade-enter fade-enter-active"
                      }
                    >
                      {getRightTabComponent()}
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        sx={{ zIndex: 9999999999999 }}
        open={snackOpen}
        autoHideDuration={5000}
        onClose={snackHandleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={snackHandleClose}
          severity="success" // Changed severity to success
          sx={{
            width: "100%",
            marginTop: "80px",
            marginLeft: lg ? "280px" : "0px",
            backgroundColor: "#388e3c", // Green shade for success
            color: "#fff",
            fontWeight: "bold",
          }}
          iconMapping={{
            success: <CheckCircleIcon sx={{ color: "#fff" }} />, // Changed to success icon
          }}
        >
          The package name has been successfully updated
        </Alert>
      </Snackbar>
    </>
  );
};

export default MyPermitGrid;
