import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Magnifier from "../customComponents/Magnifier";
import SelectPermitPackageForms from "../buildingComponents.js/SelectPermitPackageForms";
import { useDispatch, useSelector } from "react-redux";
import {
  addAvailablePermitForms,
  setNewTempPermitData,
} from "../../store/actions/dataActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LoadingButton } from "@mui/lab";
import { setExpiredSession } from "../../store/actions/sessionsActions";
import { disconnectWebsocket } from "../../store/actions/websocketActions";

const StartPermit = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const leftGridRef = useRef(null);
  const [selectedCity, setSelectedCity] = useState("");
  const [availableForms, setAvailableForms] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedCountyForms, setSelectedCountyForms] = useState([]);
  const [stormwaterSelection, setStormwaterSelection] = useState(null);

  const [countyLoading, setCountyLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const [showSelectedCity, setShowSelectedCity] = useState("");
  const [formDisplay, setFormDisplay] = useState(false);
  const [page, setPage] = useState(0);
  const [contractorIndex, setContractorIndex] = useState(0);
  const [startPermitLoading, setStartPermitLoading] = useState(false);
  const [ppName, setPpName] = useState("");

  const dispatch = useDispatch();

  const currentSession = useSelector((state) => state.sessions.currentSession);

  const up1177 = useMediaQuery("(min-width:1177px)");

  useEffect(() => {
    if (props?.fromPermit && Object.keys(props.fromPermit).length > 0) {
      const tempCity =
        props.fromPermit.selectedCity?.toLowerCase().replace(/\s+/g, "_") || "";
      setSelectedCity(tempCity);
      setAvailableForms(props?.fromPermit?.availableForms);
      setSelectedForms(props?.fromPermit?.selectedForms);
      setCities(props?.fromPermit?.cities);
      setPage(1);
      setCountyLoading(false);
    }
  }, [props?.fromPermit]);

  const handleStartPermitPackage = async () => {
    try {
      setStartPermitLoading(true);

      // Check if selectedForms contains "stormwater" and replace it with your string
      const updatedSelectedForms = selectedForms.map((form) =>
        form === "stormwater" ? stormwaterSelection : form
      );

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/start-create-permit-package`,
        {
          county: props?.county,
          city: selectedCity,
          selectedForms: updatedSelectedForms,
          selectedCountyForms: selectedCountyForms,
          contractor: props?.currentUser?.user?.contractors?.[contractorIndex],
          currentSession: currentSession,
          ppName: ppName,
        },
        { withCredentials: true }
      );

      if (resp?.data?.success) {
        await dispatch(setNewTempPermitData(resp?.data?.data));

        navigate("/permits/my-permits");
      }
    } catch (error) {
    } finally {
      setStartPermitLoading(false);
    }
  };

  const handleCloseStartPermit = () => {
    setPpName("");
    setPage(0);
    props?.setStartPermitOpen(false);
    props?.setDisplayImage([]);
    setSelectedCountyForms([]);
    setSelectedForms([]);
    if (props?.fromPermit?.fromPermit) {
      navigate(location.pathname, { replace: true, state: {} });
    }
  };

  useEffect(() => {
    if (leftGridRef.current) {
      leftGridRef.current.scrollTo(0, 0);
    }
  }, [props?.displayImage]);

  useEffect(() => {
    if (!props?.fromPermit || Object.keys(props.fromPermit).length === 0) {
      setAvailableForms([]);
      setSelectedCity("");
      setFormDisplay(false);
    }
  }, [props.countyImg, props?.startPermitOpen]);

  useEffect(() => {
    const loadPermitForms = async () => {
      try {
        setCountyLoading(true);
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/get-county-permit`,
          { currentSession: currentSession, county: props?.county },

          {
            withCredentials: true,
          }
        );
        await dispatch(addAvailablePermitForms(resp?.data?.forms));
        props?.setReadyLoad(false);
      } catch (e) {
        if (e.response?.status === 401) {
          await dispatch(disconnectWebsocket(currentSession));
          sessionStorage.removeItem("__nocCreator__r_rid");
          sessionStorage.removeItem("exp");
          setTimeout(() => {
            dispatch(setExpiredSession(true));
            navigate("/login");
          }, 100);
          navigate("/login");
        }
      } finally {
        setCountyLoading(false);
      }
    };

    if (props?.readyLoad) {
      loadPermitForms();
    }
  }, [props?.readyLoad]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "100vw",
        },
      }}
      anchor="right"
      open={props?.startPermitOpen}
      onClose={handleCloseStartPermit}
    >
      <Grid
        container
        sx={{
          display: "flex",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {/* LEFT SIDE */}
        <Grid
          item
          md={!up1177 ? 6 : 6}
          ref={leftGridRef}
          sx={{
            overflow: "auto",
            position: "relative",
            mb: 4,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100vh",
            }}
          >
            {page === 0 ? (
              props?.displayImage ? (
                props?.displayImage?.map((item, index) => (
                  <Magnifier
                    key={index}
                    src={item}
                    lensSize={400}
                    zoomFactor={4}
                  />
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <CircularProgress color="warning" size="5rem" />
                </Box>
              )
            ) : (
              <Box
                sx={{
                  mt: 10,
                  display: "flex",

                  height: "100vh",
                  // justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderRight: "4px solid #d3d3d3",
                }}
              >
                <Typography
                  mt={1}
                  variant="h3"
                  color="primary"
                  fontWeight="bold"
                  align="center"
                >
                  Forms to be Created
                </Typography>

                <Box
                  sx={{
                    overflow: "auto",
                    width: "100%",
                    pb: "80px",
                  }}
                >
                  <Box
                    sx={{
                      mt: 5,
                      width: "100%", // Adjust the max height as needed
                      px: 2, // Add padding on left and right
                      boxSizing: "border-box",
                    }}
                  >
                    {availableForms?.filter((form) =>
                      selectedForms?.includes(form.name)
                    )?.length > 0 ? (
                      <Grid
                        container
                        spacing={2}
                        sx={{ height: "100%" }}
                        mt={2}
                        mb={2}
                      >
                        {availableForms
                          ?.filter((form) => selectedForms?.includes(form.name))
                          ?.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                              <Card
                                sx={{
                                  border: "3px solid #d3d3d3",
                                  boxShadow:
                                    "6px 12px 16px 3px rgba(0, 0, 0, 0.5)",
                                  p: 1,
                                  minHeight: "14rem",
                                  // backgroundColor: "rgba(56, 142, 60, 0.3)",
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  height="140"
                                  image={`${process.env.REACT_APP_URL}${item?.cover_img}`}
                                  alt={item.label}
                                />
                                <CardContent
                                  className="bottomCard"
                                  sx={{ px: 0, pt: 1 }}
                                >
                                  <Typography
                                    align="center"
                                    variant="body2"
                                    color="primary"
                                    fontWeight="bold"
                                    component="div"
                                  >
                                    {item.name === "stormwater" &&
                                    stormwaterSelection
                                      ? item?.forms?.find(
                                          (storm) =>
                                            storm?.name === stormwaterSelection
                                        )?.label
                                      : item.label}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                      </Grid>
                    ) : (
                      selectedForms?.length === 0 && (
                        <Typography
                          align="center"
                          my={3}
                          variant="h6"
                          sx={{ color: "red" }}
                        >
                          There are no documents selected
                        </Typography>
                      )
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>

        {/* RIGHT SIDE */}
        <Grid
          item
          md={!up1177 ? 6 : 6}
          sx={{
            position: "relative",
            mb: 1,
            mt: 2,
            height: "100vh",
          }}
        >
          {/* HEADER */}
          <IconButton
            disabled={startPermitLoading}
            onClick={handleCloseStartPermit}
            sx={{ position: "absolute", right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          {/* TEXTFIELD/CARD */}
          <Box
            sx={{
              height: "calc(100% - 100px)",
              overflow: "auto",
              borderBottom: "4px solid #d3d3d3",
            }}
          >
            <SelectPermitPackageForms
              availableForms={availableForms}
              setAvailableForms={setAvailableForms}
              county={props?.county}
              selectedForms={selectedForms}
              setSelectedForms={setSelectedForms}
              selectedCountyForms={selectedCountyForms}
              setSelectedCountyForms={setSelectedCountyForms}
              displayImage={props?.displayImage}
              setDisplayImage={props?.setDisplayImage}
              selectedCity={selectedCity}
              fromPermit={props?.fromPermit?.fromPermit}
              setSelectedCity={setSelectedCity}
              showSelectedCity={showSelectedCity}
              setFormDisplay={setFormDisplay}
              availableCitiesNum={props?.availableCitiesNum}
              page={page}
              currentUser={props?.currentUser}
              contractorIndex={contractorIndex}
              setContractorIndex={setContractorIndex}
              cities={cities}
              setCities={setCities}
              countyLoading={countyLoading}
              ppName={ppName}
              setPpName={setPpName}
              stormwaterSelection={stormwaterSelection}
              setStormwaterSelection={setStormwaterSelection}
            />
          </Box>
          <Toolbar
            sx={{
              px: 0,
              pb: 1,
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              opacity: 1,
            }}
          >
            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
                  <Button
                    size="large"
                    onClick={() => {
                      setPage(page - 1);
                    }}
                    disabled={page === 0 || startPermitLoading}
                    sx={{ minWidth: "125px" }}
                    variant="contained"
                    startIcon={<ArrowBackIcon />}
                  >
                    Back
                  </Button>
                  <LoadingButton
                    loading={startPermitLoading}
                    size="large"
                    color={
                      page === 1 &&
                      props?.currentUser?.user?.contractors.length > 0
                        ? "success"
                        : "primary"
                    }
                    disabled={
                      props?.currentUser?.user?.contractors.length === 0 &&
                      page == 1
                    }
                    onClick={() => {
                      if (
                        page === 1 &&
                        props?.currentUser?.user?.contractors.length > 0
                      ) {
                        handleStartPermitPackage();
                      } else {
                        setPage(page + 1);
                      }
                    }}
                    sx={{ minWidth: "125px" }}
                    variant="contained"
                    endIcon={
                      page === 1 &&
                      props?.currentUser?.user?.contractors.length >
                        0 ? undefined : (
                        <ArrowForwardIcon />
                      )
                    }
                  >
                    {page === 1 &&
                    props?.currentUser?.user?.contractors.length > 0
                      ? "Start Creating Permit Package"
                      : "Continue"}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default StartPermit;
