import { Box, Typography } from "@mui/material";
import { convertIsoToLocalFormat } from "../../../functions/helperFunctions";
import { LoadingButton } from "@mui/lab";

const RecordingTab = (props) => {
  const row = props?.selectedRow;
  const { processing_permit } = row;
  const { recording } = processing_permit;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <img
          src="/img/website_img/record.png"
          style={{
            width: "100%",
            maxWidth: "5rem",
            height: "auto",
            borderRadius: "20px",
          }}
        />
        {!processing_permit ||
        Object.keys(processing_permit).length === 0 ||
        (!recording?.is_recorded &&
          !recording?.in_progress &&
          (!recording?.failure || recording?.failure.length === 0) &&
          !row?.notarized) ? (
          <Box mt={6}>
            <Typography align="center" px={1} variant="h6" color="primary">
              Recording requests have not yet been initiated
            </Typography>
          </Box>
        ) : !processing_permit ||
          Object.keys(processing_permit).length === 0 ||
          (!recording?.is_recorded &&
            !recording?.in_progress &&
            (!recording?.failure || recording?.failure.length === 0) &&
            row?.notarized &&
            !recording?.auto_record) ? (
          <>
            <Typography variant="h6" color="primary" fontWeight="bold" mt={2}>
              Ready to Start Recording Process
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <LoadingButton
                loading={props?.recordLoading}
                onClick={props?.handleStartRecord}
                variant="contained"
              >
                Record Notice of Commencement
              </LoadingButton>
            </Box>
          </>
        ) : recording?.in_progress && !recording?.is_recorded ? (
          <>
            <Typography variant="h6" color="primary" fontWeight="bold" mt={1}>
              Recording in progress
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="body2"
                color="primary"
                align="center"
                fontWeight="bold"
                px={1}
              >
                {convertIsoToLocalFormat(
                  recording?.started_at,
                  props?.currentUser?.subuser
                    ? props?.currentUser?.subuser?.timezone
                    : props?.currentUser?.user?.personal?.[0]?.timeZone ||
                        "America/New_York",
                  "cccc, LLLL dd, yyyy hh:mm a"
                )}
              </Typography>
            </Box>

            <Typography
              variant="body2"
              color="primary"
              align="center"
              fontWeight="bold"
              px={1}
              mt={2}
            >
              Your documents have been submitted to the recording office. Please
              allow time for their processing and review. We will notify you
              once the process is complete.
            </Typography>
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <img src="/img/gif/HI9M.gif" alt="loading" width={100} />
            </Box>
          </>
        ) : !recording?.in_progress && recording?.is_recorded ? (
          <>
            <Typography
              variant="h6"
              color="primary"
              fontWeight="bold"
              mt={2}
              px={1}
            >
              Recording Process Successfully Completed
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="body2"
                color="primary"
                align="center"
                fontWeight="bold"
                px={1}
              >
                {convertIsoToLocalFormat(
                  recording?.finished_at,
                  props?.currentUser?.subuser
                    ? props?.currentUser?.subuser?.timezone
                    : props?.currentUser?.user?.personal?.[0]?.timeZone ||
                        "America/New_York",
                  "cccc, LLLL dd, yyyy hh:mm a"
                )}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              color="primary"
              align="center"
              fontWeight="bold"
              px={1}
              mt={2}
            >
              Your Notice of Commencement document has been successfully
              recorded. You can download it using the same download button on
              the left, as previously.
            </Typography>
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default RecordingTab;
