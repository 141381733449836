import { globalForms } from "../data/permitFormsData";

const exclude_cities = ["jacksonville"];
const exclude_globals = ["energy", "noc"];

const convertToFormName = (str) => {
  // Split the string by underscores
  const words = str.split("_");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words and add 'Creating' and 'Form'
  return `Creating${capitalizedWords.join("")}Form`;
};

const preprocessName = (name) => {
  if (!name) return ""; // Handle cases where name might be undefined or null
  return name.toLowerCase().replace(/\s+/g, "_");
};

export const loadComponent = async (creatingDoc, county, city, complex) => {
  // Preprocess county and city names
  const processedCounty = preprocessName(county);
  const processedCity = preprocessName(city);
  const paisList = ["pais", "pass"];
  // Check if the document is a global component
  if (
    globalForms.includes(creatingDoc) &&
    exclude_globals.includes(creatingDoc)
  ) {
    return await import(
      `../components/permitForms/global/${convertToFormName(creatingDoc)}`
    );
  }

  // Otherwise, assume it's county/city-specific
  if (processedCity && exclude_cities.includes(processedCity)) {
    return await import(
      `../components/permitForms/${creatingDoc}/${processedCounty}/${processedCity}/${convertToFormName(
        creatingDoc
      )}`
    );
  }
  if (paisList.includes(creatingDoc)) {
    return await import(`../components/permitForms/SimplePaisForms`);
  }
  if (complex) {
    return await import(`../components/permitForms/ComplexPermitForm`);
  }
  if (!complex) {
    return await import(`../components/permitForms/SimplePermitForm`);
  }

  return null;
};
