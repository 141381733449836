// src/utils/loadComponent.js
export const globalForms = [
  "energy",
  "noc",
  "septic_system_repair",
  "septic_site_evaluation",
  "septic_construction_permit",
  "septic_worksheet",
];

export const countySpecificForms = {
  clay: ["building_permit", "builder_disclosure", "owner_permission"],
  nassau: ["pass"],
  st_johns: [
    "building_permit",
    "clearance_sheet",
    "demolition",
    "pass",
    "private_provider",
    "renovations",
    "sunroom",
  ],
};

export const commonFields = [
  {
    fields: ["permitNumber", "PermitNumber", "application_num"],
    path: ["permit_number"],
  },
  {
    name: "PrimaryAddress",
    fields: ["primaryAddress"],
    path: ["project_address"],
    min: 3,
    required: true,
  },
  {
    name: "LegalDescription",
    fields: ["legal_desc", "legalDescription"],
    path: ["documents?.noc?.legalDescription"],
    min: 3,
    required: true,
  },
  {
    name: "JobCost",
    fields: ["job_cost"],
    path: [
      "documents?.building_permit?.sections?.[0]?.fields?.job_cost",
      "documents?.building_permit?.sections?.[4]?.fields?.job_cost",
    ],
    required: true,
  },
  {
    name: "WorkDescription",
    fields: ["work_desc", "desc"],
    path: [
      "documents?.building_permit?.sections?.[0]?.fields?.work_desc",
      "documents?.building_permit?.sections?.[4]?.fields?.desc",
    ],
    min: 15,
    required: true,
  },
  {
    name: "OwnerName",
    fields: ["owner_name", "signeeName", "owner"],
    path: ["property_owner?.name"],
    required: true,
    min: 3,
  },
  {
    name: "OwnerAddress",
    fields: ["owner_address", "ownerAddress"],
    path: ["property_owner?.address"],
    required: true,
    min: 5,
  },
  {
    name: "OwnerPhone",
    fields: ["owner_phone", "ownerPhone", "ownership_phone"],
    path: ["property_owner?.mobile"],
    required: true,
    min: 12,
    specialMin: 10,
  },
  {
    name: "OwnerEmail",
    fields: ["owner_email", "ownerEmail", "ownership_email"],
    path: ["property_owner?.email"],
    required: true,
    email: true,
  },
  {
    name: "ContractorBusinessName",
    fields: [
      "contractor_name",
      "contractorName",
      "contractorCompany",
      "pivate_prov_firm",
      "contractorBName",
      "contractorBusinessName",
    ],
    path: ["contractor?.businessName"],
    required: true,
    min: 3,
  },
  {
    name: "ContractorName",
    fields: ["contractorSigneeName"],
    path: [
      "contractor?.firstName+?contractor?.middleName+contractor?.lastName",
    ],
    required: true,
    min: 3,
  },
  {
    name: "ContractorAddress",
    fields: ["contractor_address", "contractorAddress", "pivate_prov_address"],
    path: ["contractor?.mainAddress"],
    required: true,
    min: 5,
  },
  {
    name: "ContractorLicenseNumber",
    fields: [
      "contractorCertReg",
      "license_reg_num",
      "contractorCertNum",
      "contractorCert",
      "contractorLicenseNum",
    ],
    path: ["contractor?.licenseNumber"],
    required: true,
    min: 5,
  },
  {
    name: "ContractorEmail",
    fields: ["contractorEmail", "pivate_prov_email", "contractor_email"],
    path: ["contractor?.email"],
    required: true,
    email: true,
  },
  {
    fields: ["contractorFax", "pivate_prov_fax"],
    path: ["contractor?.fax"],
  },
  {
    fields: ["qualify_agent"],
    path: ["contractor?.qualify_agent?.name"],
  },
  {
    name: "ContractorPhone",
    fields: [
      "contractor_phone",
      "contractorPhone",
      "contractorMobile",
      "pivate_prov_tel",
    ],
    path: ["contractor?.mobile"],
    required: true,
    min: 12,
    specialMin: 10,
  },
  {
    name: "JobSiteContactNumber",
    fields: ["job_contact_num"],
    path: ["contractor?.job_site?.mobile"],
    min: 12,
    specialMin: 10,
  },
  {
    fields: ["workers_comp_status", "worker_compensation_group"],
    path: ["contractor?.workers_comp?.default"],
  },
  {
    fields: ["workers_comp_insurer", "compInsurer_text"],
    path: ["contractor?.workers_comp?.insurer"],
  },
  {
    fields: ["exp_date"],
    path: ["contractor?.workers_comp?.exp"],
  },
  {
    name: "ProjectName",
    fields: ["projectName"],
    path: ["project_name"],
    required: true,
    min: 5,
  },
  {
    fields: ["parcelID"],
    path: ["documents?.noc?.fields?.parcelID"],
  },
  {
    fields: ["zoning"],
    path: ["climate_zone"],
  },

  // {
  //   fields: ["stories"],
  //   path: [
  //     "documents?.building_permit?.sections?.[0]?.fields?.work_desc",
  //     "documents?.building_permit?.sections?.[4]?.fields?.desc",
  //   ],
  // },
];

export const productApprovalFields = ["pais_num"];

export const fillProductFields = ["pais_num"];

export const productInsertionMap = (fieldName, selectedProductRow) => {
  const fields = { pais_num: `FL${selectedProductRow?.sub_fl_number}` };

  return fields[fieldName];
};

export const initials = {
  signeeInitial: "property_owner?.name",
};

export const extract_legal_parts_fields = [
  "lot",
  "subdivision",
  "block",
  "platted",
];

export const unitNumMapping = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
};

export const filter_number_input_values = [
  { parentName: "num_units", formValue: "unit_" },
  { parentName: "soil_prof_a_num", formValue: "soil_prof_a_" },
  { parentName: "soil_prof_b_num", formValue: "soil_prof_b_" },
];

export const canvasForms = {
  septic_worksheet: {
    btnLabel: "CREATE SITE PLAN",
    canvasLabel: "Create Site Plan",
  },
  window_worksheet: {
    btnLabel: "CREATE WINDOW WORKSHEET",
    canvasLabel: "Create Window Worksheet",
  },
};

export const fieldsToCheckProject = ["project_contact", "project_c_phone"];
// export const fieldsToCheckFeeSimple = {
//   clay: {
//     county_specific: [
//       "fee_simple_name",
//       "fee_simple_address",
//       "fee_simple_email",
//       "fee_simple_phone",
//     ],
//   },
// };
export const fieldsToCheckFeeSimple = ["fee_simple_name", "fee_simple_address"];

export const fieldsToCheckBonding = ["bonding_name", "bonding_address"];
export const fieldsToCheckMortgage = ["mortgage_name", "mortgage_address"];

export const feeSimpleMapping = {
  nameField: "fee_simple_name",
  addressField: "fee_simple_address",
  labels: {
    feeSimple: "Fee Simple",
    feeSimpleAddress: "Fee Simple Address",
  },
};

export const bondingMapping = {
  nameField: "bonding_name",
  addressField: "bonding_address",
  labels: {
    surety: "Surety",
    suretyAddress: "Surety Address",
  },
};

export const mortgageMapping = {
  nameField: "mortgage_name",
  addressField: "mortgage_address",
  labels: {
    mortgage: "Surety",
    mortgageAddress: "Surety Address",
  },
};
