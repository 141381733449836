import {
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
  Box,
  Container,
  useMediaQuery,
  Badge,
  Drawer,
} from "@mui/material";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { green, red } from "@mui/material/colors";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import MyPermitGrid from "../../permitComponents/MyPermitGrid";
import {
  clearNewTempPermitData,
  getPermitPackages,
  getSavedProjects,
} from "../../../store/actions/dataActions";
import OpenDocuments from "../../noc/OpenDocuments";
import axios from "axios";
import { DateTime } from "luxon";

const MyPermits = () => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const savedProjects = useSelector((state) => state.nocData?.saved_projects);
  const newTempPermitData = useSelector(
    (state) => state.nocData?.newTempPermitData
  );

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const largePC = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [permitDrawerOpen, setPermitDrawerOpen] = useState(false);
  const [leftValue, setLeftValue] = useState(0);
  const [rightValue, setRightValue] = useState(0);
  const [newPermitOpened, setNewPermitOpened] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [tempLoading, setTempLoading] = useState(false);
  const [ppDownloading, setPPDownloading] = useState(false);
  const [ownerSigning, setOwnerSigning] = useState({
    notarizationNeeded: false,
    signingNeeded: false,
  });
  const [contractorSigning, setContractorSigning] = useState({
    notarizationNeeded: false,
    signingNeeded: false,
  });

  //! documents ///
  const [usingTemplate, setUsingTemplate] = useState(false);
  const [creatingDocTitle, setCreatingDocTitle] = useState("");
  const [creatingDoc, setCreatingDoc] = useState("");
  const [viewingDoc, setViewingDoc] = useState("noc");
  const [unavailableRecord, setUnavailableRecord] = useState(false);
  const [documentImage, setDocumentImage] = useState([]);
  const [downloading, setDownloading] = useState("");
  const [instructionDownloading, setInstructionDownloading] = useState("");
  const [numCreated, setNumCreated] = useState(0);
  //! /////////////

  const [paymentCards, setPaymentCards] = useState([]);

  //! DOWNLOAD INSTRUCTIONS ////////////////////////////////////////////
  const handleDownloadInstructions = async () => {
    try {
      setInstructionDownloading(
        selectedRow?.selected_documents?.[leftValue]?.name +
          "inst" +
          selectedRow?.task_id
      );
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/download-instructions`,
        {
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
          responseType: "blob",
        }
      );
      // Create a blob URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Extract the filename from the response headers if available, or default to 'download.zip'
      let fileNameRaw =
        selectedRow?.project_name + "-instructions" || "download-instructions";
      let fileName =
        fileNameRaw
          .toLowerCase()
          .replace(/\s+/g, "_")
          .replace(/[^\w\-]+/g, "") + ".zip";

      link.setAttribute("download", fileName);

      // Append the link to the body
      document.body.appendChild(link);

      // Simulate a click to download the file
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Download failed", error);
    } finally {
      setInstructionDownloading(false);
    }
  };

  const handleDownloadNoc = async (selectedRow, type) => {
    try {
      const currentDate = DateTime.now().toFormat("yyyy-MM-dd");

      // Set downloading state based on whether it's "all" or a single document
      if (type === "all") {
        setPPDownloading(true);
      } else {
        setDownloading(
          selectedRow?.selected_documents?.[leftValue]?.name +
            selectedRow?.task_id
        );
      }

      // Determine the download target: either "all" or the specific document's URL
      const downloadPDF =
        type === "all" ? "all" : selectedRow?.documents?.[creatingDoc]?.pdfUrl;
      const pdfName =
        type === "all"
          ? `notarized_permit_package_${currentDate}.zip` // Change to .zip if downloading all documents
          : selectedRow?.documents?.[creatingDoc]?.pdfName ||
            `permit_document_${creatingDoc}_${currentDate}.pdf`;

      // Perform the download request
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/download-created-file`,
        {
          pdf: downloadPDF, // "all" or specific document
          currentSession: currentSession,
          type: type === "all" ? "all" : creatingDoc, // Ensure type is passed correctly
          task_id: selectedRow?.task_id,
        },
        {
          withCredentials: true,
          responseType: "blob", // Expect binary data in response
        }
      );

      // Create a URL for the binary response (PDF or ZIP)
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      // Create an anchor element to trigger the download
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", pdfName); // Set the correct file name (PDF or ZIP)
      document.body.appendChild(fileLink);
      fileLink.click();

      // Clean up after download
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL);
    } catch (e) {
      console.error("Download error:", e);
    } finally {
      if (type === "all") {
        setPPDownloading(false); // Reset state after downloading all documents
      } else {
        setDownloading(false); // Reset state after single document download
      }
    }
  };

  useEffect(() => {
    if (selectedRow?.permit_package_created) {
      let ownerNotarizationNeeded = false;
      let ownerSigningNeeded = false;
      let contractorNotarizationNeeded = false;
      let contractorSigningNeeded = false;

      selectedRow.selected_documents.forEach((doc) => {
        if (doc.signatures.owner.sign) {
          ownerSigningNeeded = true;
        }
        if (doc.signatures.owner.notary) {
          ownerNotarizationNeeded = true;
        }
        if (doc.signatures.contractor.sign) {
          contractorSigningNeeded = true;
        }
        if (doc.signatures.contractor.notary) {
          contractorNotarizationNeeded = true;
        }
      });

      // Check if the new values differ from the current state before updating
      if (
        ownerSigning.notarizationNeeded !== ownerNotarizationNeeded ||
        ownerSigning.signingNeeded !== ownerSigningNeeded
      ) {
        setOwnerSigning({
          notarizationNeeded: ownerNotarizationNeeded,
          signingNeeded: ownerSigningNeeded,
        });
      }

      if (
        contractorSigning.notarizationNeeded !== contractorNotarizationNeeded ||
        contractorSigning.signingNeeded !== contractorSigningNeeded
      ) {
        setContractorSigning({
          notarizationNeeded: contractorNotarizationNeeded,
          signingNeeded: contractorSigningNeeded,
        });
      }
    }
  }, [selectedRow, ownerSigning, contractorSigning]);

  useEffect(() => {
    if (selectedRow) {
      const currentDoc = selectedRow?.selected_documents[leftValue];
      if (creatingDoc !== currentDoc?.name) {
        setCreatingDoc(currentDoc?.name);
      }
    }
  }, [leftValue, selectedRow]);

  useEffect(() => {
    if (selectedRow) {
      const createdCount = selectedRow?.selected_documents.filter(
        (doc) => doc.created === true
      ).length;

      if (numCreated !== createdCount) {
        setNumCreated(createdCount);
      }
    }
  }, [selectedRow]);

  const handlePermitDrawerClose = () => {
    setPermitDrawerOpen(false);
  };

  const handleCreate = () => {
    // setCreatingDoc()
    setPermitDrawerOpen(true);
  };

  useEffect(() => {
    if (selectedRow?.documents && permitDrawerOpen) {
      const currentDoc = selectedRow?.selected_documents[leftValue];
      const documentsDoc = selectedRow?.documents?.[creatingDoc];

      if (creatingDocTitle !== currentDoc?.label) {
        setCreatingDocTitle(currentDoc?.label);
      }

      if (!documentsDoc?.is_created) {
        if (
          creatingDoc === "pais" &&
          documentsDoc?.signed &&
          documentsDoc?.signed?.length > 0
        ) {
          if (documentImage !== documentsDoc?.signed) {
            setDocumentImage(documentsDoc?.signed);
          }
        } else {
          if (documentImage !== currentDoc?.img) {
            setDocumentImage(currentDoc?.img);
          }
        }
      } else {
        if (documentImage !== documentsDoc?.signed) {
          setDocumentImage(documentsDoc?.signed);
        }
      }
    }
  }, [selectedRow?.documents, permitDrawerOpen, leftValue]);

  useEffect(() => {
    setPaymentCards(
      currentUser?.payment_cards?.map((card) => {
        const lastTwo = String(card.exp_year).slice(-2);
        const first = card.exp_month;

        return {
          id: card.id,
          cardNum: "**** **** **** " + card.last4,
          cardName: card.name ? card.name : card.card_holder,
          expires:
            (first < 10 ? "0" + String(first) : String(first)) + "/" + lastTwo,
          default: card.default,
        };
      })
    );

    if (newTempPermitData && !newPermitOpened) {
      const newRow = newTempPermitData;
      setNewPermitOpened(true);
      setSelectedRow(newRow);
      setCreatingDoc("noc");
      setCreatingDocTitle("Notice of Commencement");
      setDocumentImage(newRow?.selected_documents?.[0]?.img);
      setPermitDrawerOpen(true);
      dispatch(clearNewTempPermitData());
    }
    const fetchData = async () => {
      setDataLoading(true);

      await dispatch(getSavedProjects());
      const result = await dispatch(getPermitPackages(page, pageSize));
      setRowCount(result); // Assuming your API returns the total count of documents
      setLoading(false);
      setCreatingDoc("noc");
      setCreatingDocTitle("Notice of Commencement");
      setDataLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.user?.expired === true) {
        navigate("/subscription");
      }
    }
  }, [currentUser]);

  const createdFormColumns = [
    {
      field: "package_name",
      headerName: "Package Name",
      flex: 0.8,
      renderCell: (params) => {
        const { value, row } = params;
        const isNocCreated = row.noc_created;

        return (
          <>
            {!isNocCreated && (
              <Badge
                badgeContent="New"
                color="success"
                sx={{ marginRight: 1, marginLeft: 2 }}
              />
            )}
            {value ? (
              <Typography variant="body2" sx={{ ml: !isNocCreated ? 2 : 0 }}>
                {value}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ ml: !isNocCreated ? 2 : 0 }}>
                N/A
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "project_address",
      headerName: "Project Address",
      flex: 1.8,
      renderCell: (params) => {
        return params.value ? (
          <Typography variant="body2">{params.value}</Typography>
        ) : (
          <Typography variant="body2">N/A</Typography>
        );
      },
    },
    {
      field: "county",
      headerName: "County",
      flex: 0.5,
      editable: false,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        // Get the original value from params
        const value = params.row.city;

        // If the value exists, transform it
        if (value) {
          // Replace underscores with spaces, then capitalize first letters
          return value
            .replace(/_/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        }
        return value;
      },
    },
    {
      field: "noc_created",
      headerName: "NOC Created",
      headerAlign: "center",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value ? (
              <CheckCircleIcon style={{ color: green[500] }} />
            ) : (
              <CancelIcon style={{ color: red[500] }} />
            )}
          </Box>
        );
      },
    },
    {
      field: "permit_package_created",
      headerName: "Permit Package Created",
      headerAlign: "center",
      flex: 0.7,
      editable: false,
      renderCell: (params) => {
        const hasMultipleDocuments = params.row?.selected_documents?.length > 1;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value && hasMultipleDocuments ? (
              <CheckCircleIcon style={{ color: green[500] }} />
            ) : (
              <CancelIcon style={{ color: red[500] }} />
            )}
          </Box>
        );
      },
    },
    {
      field: "notarized",
      headerName: "Notarized",
      headerAlign: "center",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value ? (
              <CheckCircleIcon style={{ color: green[500] }} />
            ) : (
              <CancelIcon style={{ color: red[500] }} />
            )}
          </Box>
        );
      },
    },

    {
      field: "recorded",
      headerName: "Recorded",
      headerAlign: "center",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {params.value ? (
              <CheckCircleIcon style={{ color: green[500] }} />
            ) : (
              <CancelIcon style={{ color: red[500] }} />
            )}
          </Box>
        );
      },
    },
    {
      field: "permit_package_added_at",
      headerName: "Initiated",
      flex: 0.6,
      editable: false,
      renderCell: (params) => {
        const timestamp = params.value * 1000; // Convert seconds to milliseconds
        const timezone = "America/New_York"; // Florida timezone
        const zonedDate = utcToZonedTime(new Date(timestamp), timezone);
        const formattedDate = format(zonedDate, "MM/dd/yyyy");
        return formattedDate;
      },
    },
  ];

  // useEffect(() => {
  //   return setSelectedRow(null);
  // }, []);

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            px: "1.5rem",
            py: "1.5rem",
          }}
        >
          <Box sx={{ maxWidth: "1920px", width: "100%", height: "100%" }}>
            <Box
              sx={{
                borderRadius: "1.5rem",
                boxShadow: "2px 3px 5px #d3d3d3",
                minHeight: "calc(100vh - 210px)",
                backgroundColor: "#fff",
                // p: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <MyPermitGrid
                  // rows={permitPackages}
                  ppDownloading={ppDownloading}
                  downloading={downloading}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  rowCount={rowCount}
                  setRowCount={setRowCount}
                  dataLoading={dataLoading}
                  setDataLoading={setDataLoading}
                  currentUser={currentUser}
                  permitPackageColumn={createdFormColumns}
                  dispatch={dispatch}
                  getPermitPackages={getPermitPackages}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  setCreatingDoc={setCreatingDoc}
                  setCreatingDocTitle={setCreatingDocTitle}
                  leftValue={leftValue}
                  setLeftValue={setLeftValue}
                  documentImage={documentImage}
                  setDocumentImage={setDocumentImage}
                  handleCreate={handleCreate}
                  tempLoading={tempLoading}
                  setTempLoading={setTempLoading}
                  handleDownloadNoc={handleDownloadNoc}
                  numCreated={numCreated}
                  permitDrawerOpen={permitDrawerOpen}
                  creatingDoc={creatingDoc}
                  cancelLoading={cancelLoading}
                  setCancelLoading={setCancelLoading}
                  currentSession={currentSession}
                  handleDownloadInstructions={handleDownloadInstructions}
                  instructionDownloading={instructionDownloading}
                  rightValue={rightValue}
                  setRightValue={setRightValue}
                  paymentCards={paymentCards}
                  contractorSigning={contractorSigning}
                  ownerSigning={ownerSigning}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Drawer
        PaperProps={{
          sx: {
            width: "100vw",
          },
        }}
        anchor="right"
        open={permitDrawerOpen}
        onClose={handlePermitDrawerClose}
      >
        <OpenDocuments
          usingTemplate={usingTemplate}
          setUsingTemplate={setUsingTemplate}
          designProList={currentUser?.design_professionals}
          savedProjects={savedProjects}
          creatingDocTitle={creatingDocTitle}
          setCreatingDocTitle={setCreatingDocTitle}
          creatingDoc={creatingDoc}
          setCreatingDoc={setCreatingDoc}
          setViewingDoc={setViewingDoc}
          viewingDoc={viewingDoc}
          location={location}
          selectedRow={selectedRow}
          unavailableRecord={unavailableRecord}
          permitDrawerOpen={permitDrawerOpen}
          setPermitDrawerOpen={setPermitDrawerOpen}
          serviceTiers={currentUser?.service_tiers}
          plan={currentUser?.tier}
          documentImage={documentImage}
          setDocumentImage={setDocumentImage}
          leftValue={leftValue}
          setLeftValue={setLeftValue}
          setTempLoading={setTempLoading}
          paymentCards={paymentCards}
          rightValue={rightValue}
          setRightValue={setRightValue}
        />
      </Drawer>
    </>
  );
};

export default MyPermits;
