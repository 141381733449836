import { Box, Grid, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import { convertObjectDateToUSString } from "../../notary/functions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const OwnerSignerTab = (props) => {
  const { contractor } = props?.selectedRow?.processing_permit?.notarization;
  const { owner } = props?.selectedRow?.processing_permit?.notarization;
  const { notarization } = props?.selectedRow?.processing_permit;

  const { signing_order } = props?.selectedRow?.processing_permit?.notarization;
  console.log(owner?.notarization?.appointments?.booked?.[0]);
  return (
    <Box>
      {signing_order === "contractor" &&
      !contractor?.notarization?.is_notarized === true &&
      !contractor?.notarization?.is_signed === true ? (
        <Typography
          variant="body2"
          color="primary"
          align="center"
          fontWeight="bold"
          px={1}
          mt={2}
        >
          Please wait for the contractor's notarization/signing to complete.
        </Typography>
      ) : (signing_order === "contractor" &&
          contractor?.notarization?.is_notarized) ||
        contractor?.notarization?.is_signed ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              color="primary"
              align="center"
              fontWeight="bold"
              px={1}
              mt={1}
            >
              {!owner?.notarization?.is_notarized
                ? "Notarization is in progress"
                : "Notarization is completed"}
            </Typography>
            {owner?.notarization?.is_notarized && (
              <CheckCircleIcon
                style={{
                  color: green[500],
                  paddingTop: 4,
                  fontSize: "30px",
                }}
              />
            )}
          </Box>
          {!notarization?.finalized ? (
            <Grid container spacing={1} px={2} mt={1}>
              <Grid item xs={10} sx={{ width: "100%" }}>
                <Typography variant="body2" fontWeight="bold">
                  The invitation email has been sent
                </Typography>
              </Grid>
              <Grid item xs={2} align="right">
                <CheckCircleIcon
                  style={{
                    color: green[500],
                  }}
                />
              </Grid>
              <Grid
                item
                xs={owner?.notarization?.waiting_to_schedule ? 10 : 6}
                sx={{ width: "100%" }}
              >
                <Typography variant="body2" fontWeight="bold">
                  RON Session Scheduled
                </Typography>
              </Grid>
              <Grid
                item
                xs={owner?.notarization?.waiting_to_schedule ? 2 : 6}
                align="right"
              >
                {owner?.notarization?.waiting_to_schedule ? (
                  <CancelIcon style={{ color: red[500] }} />
                ) : (
                  <Typography variant="body2" align="right" fontWeight="bold">
                    {convertObjectDateToUSString(
                      owner?.notarization?.appointments?.booked?.[0],
                      props?.currentUser?.user?.personal?.[0]?.timezone
                    )}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : (
            <Typography
              px={2}
              mt={2}
              align="center"
              variant="body2"
              fontWeight="bold"
              color="primary"
            >
              All documents have been successfully notarized by the Owner/Agent.
            </Typography>
          )}
        </>
      ) : signing_order === "owner" &&
        !owner?.notarization?.is_notarized &&
        !notarization?.finalized ? (
        <Grid container spacing={1} px={2} mt={1}>
          <Grid item xs={10} sx={{ width: "100%" }}>
            <Typography variant="body2" fontWeight="bold">
              The invitation email has been sent
            </Typography>
          </Grid>
          <Grid item xs={2} align="right">
            <CheckCircleIcon
              style={{
                color: green[500],
              }}
            />
          </Grid>
          <Grid
            item
            xs={owner?.notarization?.waiting_to_schedule ? 10 : 6}
            sx={{ width: "100%" }}
          >
            <Typography variant="body2" fontWeight="bold">
              RON Session Scheduled
            </Typography>
          </Grid>
          <Grid
            item
            xs={owner?.notarization?.waiting_to_schedule ? 2 : 6}
            align="right"
          >
            {owner?.notarization?.waiting_to_schedule ? (
              <CancelIcon style={{ color: red[500] }} />
            ) : (
              <Typography variant="body2" align="right" fontWeight="bold">
                {convertObjectDateToUSString(
                  owner?.notarization?.appointments?.booked?.[0],
                  props?.currentUser?.user?.personal?.[0]?.timezone
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : signing_order === "owner" &&
        owner?.notarization?.is_notarized &&
        !notarization?.finalized ? (
        <Typography
          px={2}
          mt={2}
          align="center"
          variant="body2"
          fontWeight="bold"
          color="primary"
        >
          The owner/agent has successfully notarized/signed the documents. In
          order to access your final documents, please complete the
          notarization/signing process as the contractor.
        </Typography>
      ) : Object.keys(
          props?.selectedRow?.processing_permit?.notarization?.contractor
        ).length === 0 && notarization?.finalized ? (
        <Typography
          px={2}
          mt={2}
          align="center"
          variant="body2"
          fontWeight="bold"
          color="primary"
        >
          All documents have been successfully notarized by the Owner/Agent.
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default OwnerSignerTab;
